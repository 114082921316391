<template>
    <div class="container-fluid">
   <div class="row">
      <div class="col-sm-12">
         <div class="card">
            <div class="card-header d-flex justify-content-between">
               <div class="header-title">
                  <h4 class="card-title">Lista de fornecedores</h4>
               </div>
            </div>
            <div class="card-body">
               <div class="table-responsive">
                  <div class="row justify-content-between">
                     <div class="col-sm-6 col-md-6">
                        <b-input v-model="filter" placeholder="Pesquisar"></b-input>
                        <br>
                     </div>
                     <div class="col-sm-6 col-md-6 py-2">
                        <div class="list-files d-flex">
                           <router-link class="btn bg-primary" :to="{name: 'app.supplier-add'}">
                              Adicionar
                           </router-link>
                        </div>
                     </div>
                  </div>
                  <b-table 
                     :items="suppliers" 
                     :fields="fields"
                     :per-page="perPage"
                     :current-page="currentPage"
                     :filter="filter"
                     @filtered="onFiltered"
                  >
                     <template #cell(contactoTelefonico) = "{ item }">
                        {{ item['Contacto Telefónico'] ? item['Contacto Telefónico'] : '-' }}
                     </template>
                     <template #cell(contactoEmail) = "{ item }">
                        {{ item['Contacto E-mail'] ? item['Contacto E-mail'] : '-' }}
                     </template>
                     <template #cell(actions)="{item}">
                        <div class="d-flex align-items-center list-user-action">
                           <router-link class="btn btn-sm bg-primary mr-1" :to="{name: 'app.supplier-edit', params: {supplier: item}}">
                              <i class="ri-pencil-line mr-0"></i>
                           </router-link>
                           <a 
                              class="btn btn-sm bg-primary" 
                              @click="deleteItem(item.id)" 
                              data-toggle="tooltip" 
                              data-placement="top" 
                              title=""
                              data-original-title="Delete"
                           >
                              <i class="ri-delete-bin-line mr-0"></i>
                           </a>
                        </div>                     
                        </template>
                  </b-table>             
               </div>
               <div class="row justify-content-between mt-3">
                  <div id="user-list-page-info" class="col-md-6">
                     <span>A apresentar o intervalo {{startIndex + 1}}-{{endIndex > totalRows ? totalRows : endIndex}} de {{totalRows}} registos</span>
                  </div>
                  <div class="col-md-6">
                     <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-end mb-0">
                           <bPagination 
                                :total-rows="totalRows" 
                                v-model="currentPage" 
                                :per-page="perPage" 
                                @input ="updatePage(currentPage)"
                            >
                            </bPagination>
                        </ul>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
export default {
   name:'SuppliersList',
   data() {
      return {
         suppliers: localStorage.getItem('suppliers') && Boolean(localStorage.getItem('suppliers')) ? JSON.parse(localStorage.getItem('suppliers')) : [],
         fields: ['Nome', {key: 'contactoTelefonico', label: 'Contacto Telefónico'},{key: 'contactoEmail', label: 'Contacto E-mail'}, {actions: { label: 'Ações'}}],
         perPage: 5,
         currentPage : 1,
         startIndex : 0,
         endIndex : 5,
         totalRows: 1,
         filter: null
      }
   },
   mounted () {
      if(!localStorage.getItem('suppliers') || Boolean(!localStorage.getItem('suppliers'))) {
         this.readTextFile('https://wilddourocrm.painelgestao.pt/csv/fornecedores.csv');
      }
         this.totalRows = this.suppliers.length;
      },
   methods: {
      readTextFile(file) {
         this.suppliers = [];
         let self = this;

         let rawFile = new XMLHttpRequest();
         rawFile.open("GET", file, false);
         rawFile.onreadystatechange = function ()
         {
            if(rawFile.readyState === 4)
            {
               if(rawFile.status === 200 || rawFile.status == 0)
               {
                  let csv = rawFile.responseText;
                  let lines = csv.split('\n');

                  // Get structure of object
                  let headers = lines[0].split(";") 

                  for(let line = 1; line < lines.length; line++){
                     let values = lines[line].split(";");
                     let supplier = {};

                     for (let index = 0; index < values.length - 1; index++) {
                        let key = headers[index];
                        supplier[key] = values[index];
                     }
                     supplier.id = uuidv4();
                     self.suppliers.push(supplier);
                  }        
               }
            }
         }
         rawFile.send(null);
      },
      updatePage : function(activePage) {
         this.currentPage = activePage;
         this.startIndex = (this.currentPage * 5) - 5;
         this.endIndex = this.startIndex + 5;
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      deleteItem(id) {
         this.suppliers.splice(this.suppliers.findIndex(function(i){
            return i.id === id;
         }), 1);
         localStorage.setItem('suppliers', JSON.stringify(this.suppliers))
         this.totalRows = this.suppliers.length;
      }
   },
   watch: {
      suppliers: function() {
         localStorage.setItem('suppliers', JSON.stringify(this.suppliers))
      }
   },
    
}
</script>
<style scoped>
>>>.page-link:focus{
   box-shadow: none;
}
</style>